/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import { getPlanPeriod, getPlanId, getConvertedPrice } from '@src/utils';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { Alert } from '@lesmills-international/components';
import { RichText } from 'prismic-reactjs';

import {
  getLocalStorage,
  getAutomationRecaptcha,
  enabled3DSBillingAddress,
  deleteLocalStorage,
  setLocalStorage,
  checkHaveValue,
  allowRolloverPreferences,
  defaultToMonthly,
  addErrorInDatadogRum,
  waitForElementToLoad,
} from '../../../utils/utilities';
import getSecurityChargifyToken from '../../../services/getSecurityChargifyToken';
import newSubscriptionByChargifyTokenV2 from '../../../graphql/newSubscriptionByChargifyTokenV2';
import createAffiliateSubscriptionByChargifyTokenV2 from '../../../graphql/createAffiliateSubscriptionByChargifyTokenV2';
import updatePaymentChargifyV2 from '../../../graphql/updatePaymentChargifyV2';
import getUser from '../../../graphql/getUser';
import getPlaceDetails from '../../../graphql/getPlaceDetails';
import { createClient } from '../../../services/client';
import {
  formErrorTracking,
  formSubmitTracking,
  registrationFlowTracking,
} from '../../../utils/dataTracking';
import { signUpAnalytics } from '../../../utils/segment';
import { getVoucherVerification } from '../../../utils/getVoucherVerification';
import useAffiliateVouchercodeVerification from '../../../hooks/useAffiliateVoucherCodeVerification';

import Spinner from '../../common/spinner';
import CreditCard from '../../common/payment/CreditCard';
import PayPal from '../../common/payment/PayPal';
import BillingAddress from '../../common/payment/BillingAddress';
import VoucherErrorMessage from '../../common/voucherErrorMessage';
import PaymentDetail from '../../common/payment/PaymentDetail';

import Rollover from './Rollover';
import { Wrapper, TermsCondition, PayTitle, PaymentDetailWrapper } from './style';
import PaymentMenu from './PaymentMenu';
import { PAYMENT_METHOD, countriesDisabledPaypal } from './constants';
import useStaticPaymentQuery from '../../../hooks/useStaticPaymentQuery';
import Button from './Button';
import useFormStartPushTracking from '../../../hooks/useFormStartPushTracking';
import { FormErrorName, FormName, StepLabel } from '../../../types/DataTracking';
import useFetchRolloverCountries from '../../../hooks/useFetchRolloverCountries';
import RecaptchaV2 from '../../common/recaptchaV2';
import useGetCountryCode from '../../../hooks/useGetCountryCode';
import { fixSlashes, getLangFromURL } from '../../../../../../src/utils/utilities';

const Payment = ({
  lang,
  selectedPlan,
  countriesData,
  statesData,
  discountInfo,
  voucherVerificationMessageContent,
  userInfo,
}) => {
  const { countryCode } = useGetCountryCode();
  const { allPrismicPayment } = useStaticPaymentQuery();
  const findPaymentDataByLang = allPrismicPayment?.nodes?.find((node) => node.lang === lang);
  const { givenName, familyName, email } = userInfo || {};

  const prismicData = findPaymentDataByLang?.data;
  const {
    title,
    first_payment_date_label,
    first_payment_amount_label,
    address_is_not_valid_error,
    rollover_field_error,
    payment_options,
    additional_tax,
  } = prismicData;

  const voucherVerificationMessages = {
    success: voucherVerificationMessageContent?.voucher_success || '',
    invalid: voucherVerificationMessageContent?.voucher_is_invalid || '',
    required: voucherVerificationMessageContent?.voucher_is_required || '',
    expired: voucherVerificationMessageContent?.voucher_is_expired || '',
    redeemed: voucherVerificationMessageContent?.voucher_has_been_redeemed || '',
    notRequired: voucherVerificationMessageContent?.voucher_is_not_required || '',
    countryCodeMissing: voucherVerificationMessageContent?.country_code_missing || '',
  };

  const termsConditionsData = prismicData.body;

  const {
    currency,
    product_price_point,
    trial_interval,
    offerHandle,
    name: planName,
    tier,
  } = selectedPlan || {};

  useEffect(() => {
    setSubmitDisabled(!selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    const hasFormValue = Object.values(errors)?.length;
    if (hasFormValue) {
      handleFormSubmitError(FormErrorName.INCOMPELTE_FIELDS);
    }
    if (ServerError) {
      handleFormSubmitError(FormErrorName.SERVER_ERROR);
    }
  }, [errors, ServerError, handleFormSubmitError]);

  const { interval, interval_unit, price_in_cents } = product_price_point || {};

  const DEFAULT_ERROR_MESSAGE = 'Server error, please try again.';

  const rolloverCountries = useFetchRolloverCountries();

  const isAllowRollover = useMemo(
    () => allowRolloverPreferences(countryCode, product_price_point, rolloverCountries),
    [countryCode, product_price_point, rolloverCountries]
  );

  const defaultAddressData = {
    address: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    stateCode: '',
    postalCode: '',
    countryCode: '',
  };

  const alertStyle = { marginBottom: '10px' };

  const [securityChargifyInfo, setSecurityChargifyInfo] = useState();
  const [ServerError, setServerError] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentPayment, setCurrentPayment] = useState(null);
  const [chargifyInfoData, setChargifyInfoData] = useState(null);
  const [paypalFieldError, setPaypalFieldError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errors, setErrors] = useState({
    payment: '',
    account: '',
    address: '',
    rollover: '',
  });
  const [manualAddressDefaultData, setManualAddressDefaultData] = useState(defaultAddressData);
  const [rolloverValue, setRolloverValue] = useState('');
  const [voucherSecondCheckingError, setVoucherSecondCheckingError] = useState(false);
  const [voucherSecondCheckingStatus, setVoucherSecondCheckingStatus] = useState('');

  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  const rolloverValueRef = useRef('');
  const addressDetailsRef = useRef(defaultAddressData);
  const reCaptchaRef = useRef(null);

  const { voucherVerificationStatus, voucherVerificationError } =
    useAffiliateVouchercodeVerification(discountInfo, countryCode);

  const recaptchaAutomation = getAutomationRecaptcha();

  const defaultTrackingData = useMemo(
    () => ({
      formName: FormName.PAYMENT_FORM,
      stepLabel: StepLabel.ADD_PAYMENT_START_NOW_CTA,
      planName,
      planTier: tier?.internal_tier_id,
      planBillingFreq: getPlanPeriod(interval, interval_unit),
    }),
    [tier?.internal_tier_id, interval, interval_unit, planName]
  );

  const planTrackingData = useMemo(
    () => ({
      currency,
      planPrice: getConvertedPrice(price_in_cents, currency).toString(),
      customerEmail: email,
      customerFirstName: givenName,
      customerLastName: familyName,
      planId: getPlanId(discountInfo, selectedPlan),
    }),
    [price_in_cents, email, givenName, familyName, discountInfo, selectedPlan, currency]
  );

  const { handleFormStarted } = useFormStartPushTracking(defaultTrackingData);

  const handleFormSubmitError = useCallback(
    (formErrorName) => {
      formErrorTracking({
        ...defaultTrackingData,
        formErrorName,
      });
    },
    [defaultTrackingData]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (reCaptchaRef.current && !isRecaptchaReady) {
      setIsRecaptchaReady(true);
    }
    // We want this effect to run on every render until reCaptchaRef is ready
  });

  useEffect(() => {
    const fetchSecurityToken = async () => {
      if (securityChargifyInfo || !isRecaptchaReady) return;

      try {
        const requestInput = { countryCode };

        if (recaptchaAutomation) {
          requestInput.captcha_signature = {
            auth: recaptchaAutomation.auth || '',
            nonce: recaptchaAutomation.nonce || '',
          };
          requestInput.recaptcha_token = '';
        } else {
          const reCaptchaToken = await reCaptchaRef.current.executeAsync();
          requestInput.recaptcha_token = reCaptchaToken;
          reCaptchaRef.current.reset();
        }

        await getSecurityChargifyToken(setServerError, setSecurityChargifyInfo, requestInput);
      } catch (error) {
        setServerError('Failed to get security token. Please try again.');
      }
    };

    fetchSecurityToken();
  }, [countryCode, securityChargifyInfo, recaptchaAutomation, isRecaptchaReady]);

  useEffect(() => {
    rolloverValueRef.current = rolloverValue;
    if (rolloverValue) {
      setErrors({
        ...errors,
        rollover: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolloverValue]);

  if (!selectedPlan) {
    return null;
  }

  const handleChargifyVerifyErrors = (errMsg) => {
    const message = errMsg || DEFAULT_ERROR_MESSAGE;
    setErrors({
      ...errors,
      payment: message,
    });
    setIsProcessing(false);
  };

  const updateChargifyPaymentSuccess = async () => {
    if (
      getLocalStorage('opt_in') === 'yes' &&
      process.env.GATSBY_RT_05_10_2022_ABANDONED_CART === 'true'
    ) {
      signUpAnalytics.trackPaymentCompleteEvent();
    }

    // Clear locally stored discountInfo
    deleteLocalStorage('discountInfo');

    formSubmitTracking(defaultTrackingData);

    if (recaptchaAutomation) {
      handleCreateSubscriptionByChargifyToken();
    } else {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
      handleCreateSubscriptionByChargifyToken(token);
    }
  };

  const handleChargifySuccess = (chargifyInfo) => {
    setPaypalFieldError(false);
    setErrors({
      ...errors,
      payment: null,
    });
    setChargifyInfoData(chargifyInfo);
    if (chargifyInfo && chargifyInfo.paymentType === 'CreditCard') {
      updateChargifyPaymentUtil(chargifyInfo.chargifyToken, updateChargifyPaymentSuccess);
    }
  };

  const handleCreateSubscriptionByChargifyToken = (recaptchaToken) => {
    let requestData = {};
    const isSkip3DS = getLocalStorage('skip-3ds');

    if (recaptchaToken) {
      requestData.recaptcha_token = recaptchaToken;
      requestData.recaptcha_token_V3 = '';
    } else {
      requestData.captcha_signature = {
        auth: recaptchaAutomation.auth || '',
        nonce: recaptchaAutomation.nonce || '',
      };
      requestData.recaptcha_token = '';
    }

    if (enabled3DSBillingAddress(countryCode) && isSkip3DS === true) {
      requestData.skip3ds = true;
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      product_handle,
      signedLink,
      pricePointId,
    } = JSON.parse(getLocalStorage('selected-plan'));

    if (isAllowRollover || ['nl', 'dk', 'de'].includes(countryCode)) {
      requestData = {
        ...requestData,
        default_to_monthly: defaultToMonthly({
          productHandle: product_handle,
          countryCode,
          productPricePoint: product_price_point,
          rolloverValue: rolloverValueRef.current,
          rolloverCountries,
        }),
      };
    }

    requestData = {
      ...requestData,
      product_handle,
      ...(pricePointId && {
        price_point_id: pricePointId,
      }),
    };

    const guidSignedLink = signedLink && signedLink.guid;
    if (guidSignedLink) requestData.promotion_signed_link_id = guidSignedLink;

    if (offerHandle) {
      requestData.chargifyOfferHandle = offerHandle;
    }

    // Add affiliate specific request data
    if (discountInfo?.type === 'affiliate') {
      requestData = {
        ...requestData,
        affiliateId: discountInfo?.data?.affiliateId,
        transactionId: discountInfo?.data?.transactionId,
        offerId: discountInfo?.data?.offerId,
        voucherCode: discountInfo?.data?.voucherCode,
      };
    }

    createClient
      .mutate({
        mutation: offerHandle
          ? createAffiliateSubscriptionByChargifyTokenV2
          : newSubscriptionByChargifyTokenV2,
        variables: requestData,
      })
      .then((res) => {
        // setIsProcessing(false);
        setLocalStorage(
          'new-subscription',
          JSON.stringify(
            offerHandle
              ? res?.data?.createAffiliateSubscriptionByChargifyTokenV2
              : res?.data?.newSubscriptionByChargifyTokenV2
          )
        );
        deleteLocalStorage('skip-3ds');
        const currentLang = getLangFromURL({ withLeadingSlash: false });
        navigate(fixSlashes(`/signup/success/${currentLang}`), {
          state: {
            paymentMethod: currentPayment === 'creditcard' ? 'Credit Card' : 'PayPal',
            discountInfo,
          },
        });
      })
      .catch((err) => {
        setIsProcessing(false);
        handleServerError(err, true);
        addErrorInDatadogRum(err);
      });
  };

  const handleServerError = (err) => {
    let errorMsg;
    if (err?.errors?.errorInfo) {
      errorMsg = err?.errors[0]?.errorInfo;
    } else if (err?.graphQLErrors?.length) {
      errorMsg = err.graphQLErrors[0].message;
    } else {
      errorMsg = err.toString() || DEFAULT_ERROR_MESSAGE;
    }
    if (
      err?.graphQLErrors &&
      err?.graphQLErrors?.length &&
      err?.graphQLErrors[0]?.errorType === 'voucherCodeValidation' &&
      err?.graphQLErrors[0]?.errorInfo
    ) {
      setVoucherSecondCheckingError(true);
      setVoucherSecondCheckingStatus(err?.graphQLErrors[0]?.errorInfo);
      errorMsg = err?.graphQLErrors[0]?.errorInfo;
    }

    setServerError(errorMsg);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    registrationFlowTracking({
      ...defaultTrackingData,
      ...planTrackingData,
    });

    if (
      checkHaveValue(addressDetailsRef.current) &&
      ((isAllowRollover && rolloverValue) || !isAllowRollover)
    ) {
      if (currentPayment === 'creditcard') {
        setIsProcessing(true);
        document.getElementById('submit-credit-card').click();
      } else if (currentPayment === 'paypal') {
        if (chargifyInfoData?.chargifyToken) {
          setIsProcessing(true);
          updateChargifyPaymentUtil(chargifyInfoData.chargifyToken, updateChargifyPaymentSuccess);
        } else {
          setPaypalFieldError(true);
          handleFormSubmitError(FormErrorName.SERVER_ERROR);
        }
      }
    } else {
      let rollover;
      let address;
      if (isAllowRollover && !rolloverValue) rollover = rollover_field_error;
      else address = address_is_not_valid_error;
      setErrors({
        ...errors,
        address,
        rollover,
      });
    }
  };

  const updateChargifyPaymentUtil = async (chargifyToken, callBackAfterUpdatingPaymentFn) => {
    try {
      const addressDetails = addressDetailsRef.current;

      const billingAddress = {
        addressCountry: addressDetails.countryCode.toLowerCase() || '',
        addressLocality: addressDetails.city || '',
        addressPostalCode: addressDetails.postalCode || '',
        addressRegion: addressDetails.state || '',
        addressStateCode: addressDetails.stateCode || '',
        addressStreet: addressDetails.address || '',
        addressStreet2: addressDetails.address2 || '',
      };
      const requestInput = {
        chargify_token: chargifyToken,
        billing_address: billingAddress,
      };
      if (recaptchaAutomation) {
        requestInput.captcha_signature = {
          auth: recaptchaAutomation.auth || '',
          nonce: recaptchaAutomation.nonce || '',
        };
        requestInput.recaptcha_token = '';
      } else {
        const reCaptchaToken = await reCaptchaRef.current.executeAsync();
        requestInput.recaptcha_token = reCaptchaToken;
        reCaptchaRef.current.reset();
      }
      createClient
        .mutate({
          mutation: updatePaymentChargifyV2,
          variables: requestInput,
        })
        .then(async (res) => {
          if (res?.data?.updatePaymentChargifyV2) {
            const updatedUserInfo = await createClient.query({ query: getUser });
            setLocalStorage('user-info', JSON.stringify(updatedUserInfo?.data?.getUser));
            callBackAfterUpdatingPaymentFn(updatedUserInfo?.data?.getUser);
          }
        })
        .catch(async (error) => {
          setIsProcessing(false);
          addErrorInDatadogRum(error);
          if (error.message.includes('Chargify token not found')) {
            handleServerError(DEFAULT_ERROR_MESSAGE);
          } else handleServerError(error);
        });
    } catch (error) {
      setIsProcessing(false);
      handleServerError(error);
    }
  };

  const updateFullAddress = async (address, isFromManualAddress) => {
    if (isFromManualAddress) {
      addressDetailsRef.current = {
        ...addressDetailsRef.current,
        ...address,
      };
      setErrors({
        ...errors,
        address: null,
      });
    } else if (!address) {
      setErrors({
        ...errors,
        address: address_is_not_valid_error,
      });
      addressDetailsRef.current = {
        ...addressDetailsRef.current,
        ...defaultAddressData,
      };
      setManualAddressDefaultData({
        ...manualAddressDefaultData,
        ...defaultAddressData,
      });
    } else {
      setSubmitDisabled(true);
      createClient
        .query({
          query: getPlaceDetails,
          variables: {
            placeId: address.place_id,
            language: lang.split('-')[0],
          },
        })
        .then((res) => {
          setSubmitDisabled(false);
          addressDetailsRef.current = {
            ...addressDetailsRef.current,
            ...res?.data?.getPlaceDetails,
          };
          setManualAddressDefaultData({
            ...manualAddressDefaultData,
            ...res?.data?.getPlaceDetails,
          });
        })
        .catch((error) => {
          handleServerError(error);
          addErrorInDatadogRum(error);
        });
      setErrors({
        ...errors,
        address: null,
      });
    }
  };

  const renderTermsConditions = () => {
    const isUS = countryCode === 'us';
    let usTerms = [];
    let otherTerms = [];

    termsConditionsData?.forEach((term) => {
      if (term.primary?.subscription_type?.text === 'us') {
        usTerms = term.items;
      } else {
        otherTerms = term.items;
      }
    });
    return (
      <TermsCondition>
        {isUS &&
          usTerms.map((term) => (
            <RichText
              key={term.subscription_description?.text}
              render={term.subscription_description?.richText}
            />
          ))}
        {!isUS &&
          otherTerms.map((term) => (
            <RichText
              key={term.subscription_description?.text}
              render={term.subscription_description?.richText}
            />
          ))}
      </TermsCondition>
    );
  };

  // wait for the google recaptcha html elements to load, check every 3 seconds
  const reCaptchaClickOutsideTheBox = () => {
    setIsProcessing(false);
  };
  waitForElementToLoad('#g-recaptcha-response', 3000)
    .then(() => {
      const el = document.querySelector(
        'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]'
      );
      el?.parentNode?.parentNode?.addEventListener('click', reCaptchaClickOutsideTheBox);
    })
    .catch(() => {});

  let voucherAlertInfo = { type: '', message: '' };
  if (voucherVerificationStatus && voucherVerificationError) {
    voucherAlertInfo = getVoucherVerification(
      voucherVerificationStatus,
      voucherVerificationMessages
    );
  }

  if (voucherSecondCheckingStatus && voucherSecondCheckingError) {
    voucherAlertInfo = getVoucherVerification(
      voucherSecondCheckingStatus,
      voucherVerificationMessages
    );
  }

  const displayVoucherError =
    (voucherVerificationStatus && voucherVerificationError) ||
    (voucherSecondCheckingStatus && voucherSecondCheckingError);

  const renderPaymentDetail = () => (
    <PaymentDetailWrapper>
      <PaymentDetail
        dateLabel={first_payment_date_label}
        amountLabel={first_payment_amount_label}
        trialInterval={trial_interval}
        lang={lang}
        currency={currency}
        priceInCents={price_in_cents}
        taxLabel={additional_tax}
      />
    </PaymentDetailWrapper>
  );

  const renderAddressField = () => (
    <BillingAddress
      prismicData={prismicData}
      lang={lang}
      countryCode={countryCode}
      updateFullAddress={updateFullAddress}
      error={errors.address}
      defaultManualAddress={manualAddressDefaultData}
      countriesData={countriesData}
      statesData={statesData}
      disabled={voucherVerificationError}
    />
  );

  const mapPaymentContent = (type) => {
    switch (type) {
      case PAYMENT_METHOD.CREDITCARD:
        return (
          <>
            <CreditCard
              prismicData={prismicData}
              securityChargifyInfo={securityChargifyInfo}
              currentCountry={countryCode}
              onGetChargifySuccess={handleChargifySuccess}
              handleChargifyVerifyErrors={handleChargifyVerifyErrors}
              disabled={voucherVerificationError}
            />
            {renderAddressField()}
            {renderPaymentDetail()}
          </>
        );

      case PAYMENT_METHOD.PAYPAL:
        return (
          <>
            <PayPal
              prismicData={prismicData}
              securityChargifyInfo={securityChargifyInfo}
              paypalFieldError={paypalFieldError}
              onGetChargifySuccess={handleChargifySuccess}
              handleChargifyVerifyErrors={handleChargifyVerifyErrors}
              disabled={voucherVerificationError}
            />
            {renderAddressField()}
            {renderPaymentDetail()}
          </>
        );

      default:
        return null;
    }
  };

  const menuItems = () => {
    let paymentMethods = payment_options;

    // filter for paypal disabled countries
    if (countriesDisabledPaypal.includes(countryCode)) {
      paymentMethods = paymentMethods?.filter(
        ({ payment_type }) => payment_type !== PAYMENT_METHOD.PAYPAL
      );
    }

    return paymentMethods?.map(({ payment_type, icon, payment_name }) => ({
      title: payment_name,
      icon,
      content: mapPaymentContent(payment_type),
      onExpand: () => {
        handleFormStarted();
        setCurrentPayment(payment_type);

        registrationFlowTracking({
          ...defaultTrackingData,
          ...planTrackingData,
          stepLabel: StepLabel.PAYMENT_SELECTED,
        });
      },
      onClose: () => setCurrentPayment(null),
    }));
  };

  return (
    <Wrapper id="payment-wrapper">
      <PayTitle>{title}</PayTitle>
      {!!displayVoucherError && (
        <Alert severity={voucherAlertInfo?.type} style={{ marginBottom: '10px' }}>
          <VoucherErrorMessage
            message={voucherAlertInfo?.message}
            voucherCode={discountInfo?.data?.voucherCode}
            status={voucherVerificationStatus}
            linkGroup={{
              homepage: voucherVerificationMessageContent?.homepage_link,
              landingPage: voucherVerificationMessageContent?.bau_landing_page_link,
              support: voucherVerificationMessageContent?.customer_support_link,
            }}
          />
        </Alert>
      )}
      {ServerError && (
        <Alert severity="error" style={alertStyle}>
          {ServerError}
        </Alert>
      )}
      {errors?.payment && (
        <Alert severity="error" style={alertStyle}>
          {errors?.payment}
        </Alert>
      )}

      {securityChargifyInfo ? <PaymentMenu items={menuItems()} /> : <Spinner />}

      <Rollover
        prismicData={prismicData}
        value={rolloverValue}
        countryCode={countryCode}
        selectedProductData={product_price_point}
        error={errors.rollover}
        rolloverCountries={rolloverCountries}
        handleOnChange={(event) => {
          setRolloverValue(event.target.value);
        }}
        resetRolloverValue={() => setRolloverValue('')}
      />
      {renderTermsConditions()}
      <Button
        paymentOptions={payment_options}
        currentPayment={currentPayment}
        chargifyInfoData={chargifyInfoData}
        isProcessing={isProcessing}
        isDisabled={submitDisabled || voucherVerificationError || !currentPayment}
        handleSubmit={handleSubmit}
      />
      <RecaptchaV2 ref={reCaptchaRef} />
    </Wrapper>
  );
};

Payment.defaultProps = {
  data: {},
  lang: 'en-nz',
  selectedPlan: {},
};

Payment.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  data: PropTypes.object,
  lang: PropTypes.string,
  selectedPlan: PropTypes.object,
};

export default Payment;

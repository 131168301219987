import { gql } from '@apollo/client';

const getChargifySecurityToken = gql`
  query getChargifySecurityToken(
    $countryCode: String
    $captcha_signature: CaptchaSignatureInput
    $recaptcha_token: String
    $recaptcha_token_V3: String
  ) {
    getChargifySecurityToken(
      countryCode: $countryCode
      captcha_signature: $captcha_signature
      recaptcha_token: $recaptcha_token
      recaptcha_token_V3: $recaptcha_token_V3
    ) {
      securityToken
      subdomain
      publicKey
    }
  }
`;

export default getChargifySecurityToken;

import { useMemo } from 'react';
import { allowRolloverPreferences } from '../utils/utilities';
import useFetchRolloverCountries from './useFetchRolloverCountries';
import useGetCountryCode from './useGetCountryCode';

export const useIsRolloverAllowed = (product) => {
  const { countryCode } = useGetCountryCode();
  const rolloverCountries = useFetchRolloverCountries();

  return useMemo(
    () => allowRolloverPreferences(countryCode, product?.product_price_point, rolloverCountries),
    [countryCode, product?.product_price_point, rolloverCountries]
  );
};

export default useIsRolloverAllowed;

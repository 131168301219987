import React from 'react';
import {
  Layout,
  LegalCountryNavigation,
  LegalNavigation,
  withPublicRoute,
} from '@components/molecules';
import { LegalRichText } from '@components/atoms';
import { graphql } from 'gatsby';
import { Theme } from '@components/molecules/layout';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import {
  Privacy_Policy_Page,
  Privacy_Policy_PageBodyPrivacy_Policy,
} from '../../generated/prismicGraphql';
import { COUNTRY_CODES, replaceSpaceInString } from '../../utils/legalPageUtils';
import {
  SectionContainer,
  TitleContainer,
  ContentContainer,
  CountryTitle,
  LastUpdateLabel,
  LegalHeader,
  KeypointsContainer,
  LegalTitle,
  StyledHr,
} from './legalPageStyles';

interface PolicyData {
  policy_content: {
    raw: any[];
    text: string;
  };
  policy_id: string;
  policy_title: {
    raw: any[];
    text: string;
  };
}

interface Policy {
  policy_title: {
    text: string;
  };
}

interface NavigationItem {
  text: string;
  url: string;
}

interface ExtendedPrivacyPolicyBody extends Privacy_Policy_PageBodyPrivacy_Policy {
  items: PolicyData[];
}

interface PrivacyPolicyPageProps {
  data: {
    prismicPrivacyPolicyPage: {
      data: Privacy_Policy_Page;
    };
  };
}

const generatePolicyNavs = (policies: Policy[], country: string): NavigationItem[] =>
  policies.map(({ policy_title = { text: '' } }) => {
    const titlePolicy = policy_title.text;

    const cleanTitle = titlePolicy.replace(/^\d+\.?\s*/, '');

    const formattedTitle = cleanTitle
      ? cleanTitle.charAt(0).toUpperCase() + cleanTitle.slice(1).toLowerCase()
      : '';

    return {
      text: formattedTitle,
      url: `#${replaceSpaceInString(cleanTitle.toLowerCase(), '_')}_${country}`,
    };
  });

const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = ({ data }) => {
  const sliceData: Privacy_Policy_Page = data.prismicPrivacyPolicyPage.data;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, body: blocksForEachCountry, page_title } = sliceData;

  const countryNavItems = blocksForEachCountry?.map((countryBlock) => ({
    text: countryBlock.primary?.country_nav_label?.text,
    url: `#${replaceSpaceInString(countryBlock.primary?.country_code || '', '_')}`,
  }));

  return (
    <Layout theme={Theme.White} metaData={{ title: page_title?.text }}>
      <SectionContainer>
        <TitleContainer>
          <LegalHeader>LES MILLS MEDIA LTD</LegalHeader>
          <LegalRichText text={title} />
        </TitleContainer>
      </SectionContainer>

      <SectionContainer>
        <LegalCountryNavigation navs={countryNavItems} />
      </SectionContainer>

      <SectionContainer>
        {blocksForEachCountry?.map((countryBlock, index) => {
          const { primary, items: policies } = countryBlock as ExtendedPrivacyPolicyBody;
          const countryCode = primary?.country_code || 'US';
          const policyNavs = generatePolicyNavs(policies, countryCode);
          const isArabic = countryCode === COUNTRY_CODES.UNITED_ARAB_EMIRATES;

          return (
            <ContentContainer
              key={index}
              isArabic={isArabic}
              id={replaceSpaceInString(countryCode, '_')}
            >
              {primary?.privacy_policy_country_title && (
                <CountryTitle isArabic={isArabic}>
                  {primary.privacy_policy_country_title.text}
                </CountryTitle>
              )}

              {primary?.last_updated_label && (
                <LastUpdateLabel isArabic={isArabic}>
                  {primary.last_updated_label.text}
                </LastUpdateLabel>
              )}
              {primary?.privacy_policy_country_content && (
                <LegalRichText text={primary.privacy_policy_country_content.raw} />
              )}

              {primary?.keypoint_title && primary?.keypoint_content && (
                <KeypointsContainer>
                  <LegalRichText text={primary.keypoint_title.raw} />
                  <LegalRichText text={primary.keypoint_content.raw} />
                </KeypointsContainer>
              )}

              <LegalNavigation navs={policyNavs} countryCode={countryCode} />

              {policies?.map((policy, policyIndex) => {
                const policyTitle = policy.policy_title.text;
                const cleanTitle = policyTitle.replace(/^\d+\.\s*/, '');
                const policyId = `${replaceSpaceInString(
                  cleanTitle.toLowerCase(),
                  '_'
                )}_${countryCode}`;

                return (
                  <section key={policyIndex} id={policyId}>
                    <LegalTitle isArabic={isArabic}>{policyTitle}</LegalTitle>
                    <LegalRichText text={policy.policy_content.raw} />
                  </section>
                );
              })}
              <StyledHr />
            </ContentContainer>
          );
        })}
      </SectionContainer>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicPrivacyPolicyPage {
      data {
        body {
          ... on PrismicPrivacyPolicyPageDataBodyPrivacyPolicy {
            items {
              policy_content {
                raw
                text
              }
              policy_id
              policy_title {
                raw
                text
              }
            }
            primary {
              back_to_top_button
              country_code
              country_nav_label {
                raw
                text
              }
              intro_policy_text {
                raw
                text
              }
              keypoint_content {
                raw
                text
              }
              keypoint_title {
                raw
                text
              }
              last_updated_label {
                raw
                text
              }
              privacy_policy_country_content {
                raw
                text
              }
              privacy_policy_country_title {
                raw
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicPrivacyPolicyPageDataBody1AnnexTable {
            items {
              country_data {
                raw
                text
              }
              protection_authority_data {
                raw
                text
              }
            }
            primary {
              annex_content {
                raw
                text
              }
              annex_subtitle {
                raw
                text
              }
              annex_title {
                raw
                text
              }
              country_code
              country_header {
                raw
                text
              }
              protection_authority_header {
                raw
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicPrivacyPolicyPageDataBody2PolicyNavigations {
            items {
              policy_navigation_label
              policy_navigation_url
            }
            primary {
              country_code
            }
          }
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_title {
          text
        }
        page_og_description {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        title {
          text
          raw
        }
      }
    }
  }
`;
export default withPrismicPreview(withPublicRoute(PrivacyPolicyPage));

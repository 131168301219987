import { PROGRAM_TEASER_TITLE } from '@constants';
import { contentClickTracking } from '@src/utils';
import { User } from '@backend';
import { formatDuration } from './formatDuration';
import ROUTES from '../../../../src/constants/routes';

const COLLECTION_LINK = 'lesMillsPlusBackend_CollectionLinkItem';

const isCollectionLink = (item) => item?.__typename === COLLECTION_LINK;

const getLink = (item, isAvailableToTier, planId) => {
  if (isCollectionLink(item)) {
    return `/collection/${item.slug}`;
  }

  if (isAvailableToTier) {
    const baseUrl = `/workout/${encodeURIComponent(item?.name || item?.workoutName || '')}`;
    return planId ? `${baseUrl}?planId=${planId}` : baseUrl;
  }
  return ROUTES().SUBSCRIPTION;
};

interface WorkoutItem {
  durationInSeconds?: number;
  tracks?: string[];
  categories?: string[];
  equipment?: string[];
  imageWide?: string;
  instructorTeam?: string[];
  programName?: string;
  completionCount?: number;
  publishDate?: number;
  filterTags?: string[];
  tiers?: string[];
  vimeoWebPageUrl?: string;
  workoutName?: string;
  duration?: number;
  itemName?: string;
  name?: string;
  language?: string;
  vimeoThumbnail?: string;
  workout?: {
    categories: string[];
    tiers: string[];
    equipment: string[];
  };
}

export interface ExtendedUserInfo extends User {
  tier: 'base' | 'premium';
}

interface WorkOutVideoCard {
  item: WorkoutItem;
  userInfo: ExtendedUserInfo;
  index?: number;
  contentListTitle?: string;
  contentListIndex?: number;
  contentLocation?: string;
  isEngApi?: boolean;
  hideEquipment?: boolean;
  planId?: string | null;
}

const getWorkoutForVideoCard = ({
  isEngApi = false,
  item,
  userInfo,
  index,
  contentListTitle,
  contentListIndex,
  contentLocation,
  hideEquipment = false,
  planId,
}: WorkOutVideoCard) => {
  const duration = item?.durationInSeconds || item?.assets?.items[0]?.duration;
  const categories = item?.categories || item?.workout?.categories || [];
  const tiers = item?.tiers || item?.workout?.tiers;
  const isAvailableToTier = !tiers || tiers.includes(userInfo.tier);
  const instructorTeam = isEngApi
    ? item?.tracks?.flatMap((obj) => obj.presenters)
    : item?.instructorTeam;

  return {
    name: item?.itemName || item?.name || item?.workoutName,
    linkText: isCollectionLink(item) && 'ALBUM',
    backgroundImg: item?.imageWide || item?.vimeoThumbnail,
    duration: formatDuration(duration),
    equipmentIcons: hideEquipment ? null : item?.equipment || item?.workout?.equipment || [],
    categories: [...categories],
    subCategory: item?.filterTags,
    publishDate: item?.publishDate,
    instructorTeam,
    language: item?.language || 'English',
    completedTimes: item?.completionCount,
    teaseText: !isAvailableToTier && PROGRAM_TEASER_TITLE,
    link: getLink(item, isAvailableToTier, planId),
    onClick: () => {
      contentClickTracking({
        contentIndex: index,
        contentListTitle,
        contentListIndex,
        contentTitle: item?.workoutName,
        contentType: 'video',
        contentTopic: item?.programName,
        contentLocation,
      });
    },
  };
};

export default getWorkoutForVideoCard;

import { ApolloQueryResult } from '@apollo/client';
import captureException from '../utils/sentry';
import getChargifySecurityToken from '../graphql/getChargifySecurityToken';
import { createClient } from './client';

interface SetErrorsFunction {
  (errors: { payment: string }): void;
}

interface HandleResponseFunction {
  (token: string): void;
}

interface RequestInput {
  countryCode?: string;
  recaptcha_token?: string;
  captcha_signature?: {
    auth: string;
    nonce: string;
  };
}

interface ChargifyTokenResponse {
  getChargifySecurityToken: string;
}

const getSecurityChargifyToken = async (
  setErrors: SetErrorsFunction,
  handleResponse: HandleResponseFunction,
  requestInput: RequestInput
): Promise<void> => {
  const response: ApolloQueryResult<ChargifyTokenResponse> = await createClient.query({
    query: getChargifySecurityToken,
    variables: requestInput,
  });
  if (response.errors && response.errors.length > 0) {
    setErrors({
      payment: response.errors[0].message,
    });
    captureException({
      action: 'getSercurityChargifyToken',
      ...response.errors[0],
    });
  } else {
    handleResponse(response.data.getChargifySecurityToken);
  }
};

export default getSecurityChargifyToken;

/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useRef, useContext } from 'react';

// eslint-disable-next-line import/no-relative-packages
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import {
  getAutomationRecaptcha,
  checkHaveValue,
  addErrorInDatadogRum,
} from '../../utils/utilities';
import getSecurityChargifyToken from '../../services/getSecurityChargifyToken';
import updatePaymentChargifyV2 from '../../graphql/updatePaymentChargifyV2';
import getPlaceDetails from '../../graphql/getPlaceDetails';
import { createClient } from '../../services/client';
import normalizeServerErrorMsg from '../../utils/normalizeServerErrorMsg';
import Spinner from '../common/spinner';
import Alert from '../common/alert';
import CreditCard from '../common/payment/CreditCard';
import PayPal from '../common/payment/PayPal';
import BillingAddress from '../common/payment/BillingAddress';

import { Wrapper, Container } from './style';
import useStaticPaymentQuery from '../../hooks/useStaticPaymentQuery';
import PaymentMenu from '../signup/payment/PaymentMenu';
import { PAYMENT_METHOD, countriesDisabledPaypal } from '../signup/payment/constants';
import Button from '../signup/payment/Button';
import RecaptchaV2 from '../common/recaptchaV2';
import useDisplayRecaptchaBadge from '../../hooks/useDisplayRecaptchaBadge';

const Payment = ({ lang, countryCode, countriesData, statesData, prePage, preSelectedProduct }) => {
  const { allPrismicPayment } = useStaticPaymentQuery();
  const findPaymentDataByLang = allPrismicPayment?.nodes?.find((node) => node.lang === lang);
  const prismicData = findPaymentDataByLang?.data;
  const { address_is_not_valid_error, update_payment_options } = prismicData;

  const DEFAULT_ERROR_MESSAGE = 'Server error, please try again.';

  const userInfo = useContext(userContext);

  const userCountryCode = userInfo?.addressCountry?.toLowerCase() || countryCode;

  const countryName = countriesData?.items.filter(
    (country) => country.country_code?.text.toLowerCase() === userCountryCode
  )[0]?.country_name?.text;

  const currentAddress = {
    address: userInfo?.addressStreet || '',
    address2: userInfo?.addressStreet2 || '',
    city: userInfo?.addressLocality || '',
    state: userInfo?.addressRegion || '',
    country: countryName || '',
  };

  const defaultAddressData = {
    address: userInfo?.addressStreet || '',
    address2: userInfo?.addressStreet2 || '',
    city: userInfo?.addressLocality || '',
    state: userInfo?.addressRegion || '',
    country: countryName || '',
    stateCode: userInfo?.addressStateCode || '',
    postalCode: userInfo?.addressPostalCode || '',
    countryCode: userCountryCode || '',
  };

  const [securityChargifyInfo, setSecurityChargifyInfo] = useState();
  const [serverError, setServerError] = useState();
  const [currentPayment, setCurrentPayment] = useState(null);
  const [chargifyInfoData, setChargifyInfoData] = useState(null);
  const [paypalFieldError, setPaypalFieldError] = useState(false);
  const [errors, setErrors] = useState({
    payment: '',
    account: '',
    address: '',
  });
  const [manualAddressDefaultData, setManualAddressDefaultData] = useState(defaultAddressData);
  const [isProcessing, setIsProcessing] = useState(false);

  const addressDetailsRef = useRef(defaultAddressData);
  const reCaptchaRef = useRef(null);

  const recaptchaAutomation = getAutomationRecaptcha();

  useEffect(() => {
    const fetchSecurityToken = async () => {
      if (securityChargifyInfo || !reCaptchaRef.current) return;

      try {
        const requestInput = { countryCode: userCountryCode };

        if (recaptchaAutomation) {
          requestInput.captcha_signature = {
            auth: recaptchaAutomation.auth || '',
            nonce: recaptchaAutomation.nonce || '',
          };
          requestInput.recaptcha_token = '';
        } else {
          const reCaptchaToken = await reCaptchaRef.current.executeAsync();
          requestInput.recaptcha_token = reCaptchaToken;
          reCaptchaRef.current.reset();
        }

        await getSecurityChargifyToken(setServerError, setSecurityChargifyInfo, requestInput);
      } catch (error) {
        setServerError('Failed to get security token. Please try again.');
      }
    };

    fetchSecurityToken();
  }, [userCountryCode, securityChargifyInfo, recaptchaAutomation, reCaptchaRef]);

  const handleChargifyVerifyErrors = (errMsg) => {
    const message = errMsg || DEFAULT_ERROR_MESSAGE;
    setErrors({
      ...errors,
      payment: message,
    });
    setIsProcessing(false);
  };

  const handleChargifySuccess = (chargifyInfo) => {
    setPaypalFieldError(false);
    setErrors({
      ...errors,
      payment: null,
    });
    setChargifyInfoData(chargifyInfo);
    if (chargifyInfo && chargifyInfo.paymentType === 'CreditCard') {
      updateChargifyPaymentUtil(chargifyInfo.chargifyToken);
    }
  };

  useDisplayRecaptchaBadge();

  const updateChargifyPaymentUtil = async (chargifyToken) => {
    const addressDetails = addressDetailsRef.current;

    const billingAddress = {
      addressCountry: addressDetails.countryCode.toLowerCase() || '',
      addressLocality: addressDetails.city || '',
      addressPostalCode: addressDetails.postalCode || '',
      addressRegion: addressDetails.state || '',
      addressStateCode: addressDetails.stateCode || '',
      addressStreet: addressDetails.address || '',
      addressStreet2: addressDetails.address2 || '',
    };

    const handleUpdatePaymentChargifyV2 = async (chargifyTokenPayment, billingAddressPayment) => {
      const requestInput = {
        chargify_token: chargifyTokenPayment,
        billing_address: billingAddressPayment,
      };
      if (recaptchaAutomation) {
        requestInput.captcha_signature = {
          auth: recaptchaAutomation.auth || '',
          nonce: recaptchaAutomation.nonce || '',
        };
        requestInput.recaptcha_token = '';
      } else {
        const reCaptchaToken = await reCaptchaRef.current.executeAsync();
        requestInput.recaptcha_token = reCaptchaToken;
        reCaptchaRef.current.reset();
      }
      try {
        setIsProcessing(true);
        createClient
          .mutate({
            mutation: updatePaymentChargifyV2,
            variables: requestInput,
          })
          .then(async (res) => {
            if (res?.data?.updatePaymentChargifyV2) {
              setIsProcessing(false);
              if (preSelectedProduct) {
                window.history.pushState({ preSelectedProduct }, '', prePage);
              }
              window.location.href = prePage;
            }
          })
          .catch((error) => {
            setIsProcessing(false);
            setServerError(normalizeServerErrorMsg(error));
            addErrorInDatadogRum(error);
          });
      } catch (error) {
        setIsProcessing(false);
        setServerError(normalizeServerErrorMsg(error));
        addErrorInDatadogRum(error);
      }
    };
    handleUpdatePaymentChargifyV2(chargifyToken, billingAddress);
  };

  const updateFullAddress = async (address, isFromManualAddress) => {
    if (isFromManualAddress) {
      addressDetailsRef.current = {
        ...addressDetailsRef.current,
        ...address,
      };
      setErrors({
        ...errors,
        address: null,
      });
    } else if (!address) {
      setErrors({
        ...errors,
        address: address_is_not_valid_error.text,
      });
      addressDetailsRef.current = {
        ...addressDetailsRef.current,
        ...defaultAddressData,
      };
      setManualAddressDefaultData({
        ...manualAddressDefaultData,
        ...defaultAddressData,
      });
    } else {
      createClient
        .query({
          query: getPlaceDetails,
          variables: {
            placeId: address.place_id,
            language: lang.split('-')[0],
          },
        })
        .then((res) => {
          addressDetailsRef.current = {
            ...addressDetailsRef.current,
            ...res?.data?.getPlaceDetails,
          };
          setManualAddressDefaultData({
            ...manualAddressDefaultData,
            ...res?.data?.getPlaceDetails,
          });
        })
        .catch((error) => {
          setServerError(normalizeServerErrorMsg(error));
          addErrorInDatadogRum(error);
        });
      setErrors({
        ...errors,
        address: null,
      });
    }
  };

  const renderAddressField = () => (
    <BillingAddress
      prismicData={prismicData}
      lang={lang}
      countryCode={userCountryCode}
      updateFullAddress={updateFullAddress}
      error={errors.address}
      defaultManualAddress={manualAddressDefaultData}
      countriesData={countriesData}
      statesData={statesData}
      currentUserAddress={currentAddress}
    />
  );

  const mapPaymentContent = (type) => {
    switch (type) {
      case PAYMENT_METHOD.CREDITCARD:
        return (
          <>
            <CreditCard
              prismicData={prismicData}
              securityChargifyInfo={securityChargifyInfo}
              currentCountry={userCountryCode}
              onGetChargifySuccess={handleChargifySuccess}
              handleChargifyVerifyErrors={handleChargifyVerifyErrors}
            />
            {renderAddressField()}
          </>
        );

      case PAYMENT_METHOD.PAYPAL:
        return (
          <>
            <PayPal
              prismicData={prismicData}
              securityChargifyInfo={securityChargifyInfo}
              paypalFieldError={paypalFieldError}
              onGetChargifySuccess={handleChargifySuccess}
              handleChargifyVerifyErrors={handleChargifyVerifyErrors}
            />
            {renderAddressField()}
          </>
        );

      default:
        return null;
    }
  };

  const menuItems = () => {
    let paymentMethods = update_payment_options;

    // filter for paypal disabled countries
    if (countriesDisabledPaypal.includes(userCountryCode)) {
      paymentMethods = paymentMethods?.filter(
        ({ payment_type }) => payment_type !== PAYMENT_METHOD.PAYPAL
      );
    }

    return paymentMethods?.map(({ payment_type, icon, payment_name }) => ({
      title: payment_name,
      icon,
      content: mapPaymentContent(payment_type),
      onExpand: () => setCurrentPayment(payment_type),
      onClose: () => setCurrentPayment(null),
    }));
  };

  const handleSubmit = (event) => {
    try {
      event.preventDefault();
      if (checkHaveValue(addressDetailsRef.current)) {
        if (currentPayment === 'creditcard') {
          setIsProcessing(true);
          document.getElementById('submit-credit-card').click();
        } else if (currentPayment === 'paypal') {
          if (chargifyInfoData?.chargifyToken) {
            setIsProcessing(true);
            updateChargifyPaymentUtil(chargifyInfoData.chargifyToken);
          } else {
            setPaypalFieldError(true);
          }
        }
      } else {
        setErrors({
          ...errors,
          address: address_is_not_valid_error.text,
        });
      }
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  };

  return (
    <Wrapper id="payment-wrapper">
      {serverError && (
        <Alert content={serverError} style={{ width: '100%', marginBottom: '20px' }} />
      )}
      {errors?.payment && (
        <Alert content={errors?.payment} style={{ width: '100%', marginBottom: '20px' }} />
      )}
      <Container>
        {securityChargifyInfo ? <PaymentMenu items={menuItems()} /> : <Spinner />}
        <RecaptchaV2 ref={reCaptchaRef} />
        <Button
          paymentOptions={update_payment_options}
          currentPayment={currentPayment}
          chargifyInfoData={chargifyInfoData}
          isProcessing={isProcessing}
          isDisabled={!currentPayment}
          handleSubmit={handleSubmit}
        />
      </Container>
    </Wrapper>
  );
};

export default Payment;

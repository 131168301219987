// TODO: refactor this component - too many logics in one component
import React, { ChangeEvent, useState, useEffect, useContext, useRef } from 'react';
import { Button } from '@lesmills-international/components';
import { Auth } from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
/* eslint-disable import/no-relative-packages */
import { Product } from '@src/type/Product';
import { parseJwt } from '@src/utils';
import { TIERS } from '@constants';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import { addErrorInDatadogRum, priceFormat } from '../../../utils/utilities';
import { normalizeTieringPriceData } from '../../../utils/normalizePriceData';
import { createClient, createPublicClient } from '../../../services/client';
import getValidProducts from '../../../graphql/getValidProducts';
import { normalizePlans } from '../../signup/selectPlan/normalizePlansPromotions';
import changeSubscriptionChargifyJs from '../../../graphql/changeSubscriptionChargifyJs';
import normalizeServerErrorMsg from '../../../utils/normalizeServerErrorMsg';
import SubscriptionManagementLayout from '../../common/subscriptionManagementLayout';
import CurrentPaymentInfo from '../currentPaymentInfo';
import AvailableTieringSubscriptions from '../availableTieringSubscriptions';
import SuccessMsg from '../successMsg';
import Alert from '../../common/alert';
import WarningAlert from '../warningAlert';
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/right-arrow.svg';
import WaitingTime from '../../../constants/refreshTokenWaitingTime';
import {
  WrapperTiering,
  TermsCondition,
  Divider,
  PaymentWrapper,
  PricingCardButtonContainer,
  NewSubWrapper,
  RightArrowIconWrapper,
  TextWrapper,
  SubTitle,
  LinkText,
} from './style';
import { Title, Detail, Reminder } from '../rolloverPreference/styles';
import RadioGroup from '../../common/radioGroup';
import ProcessingMsg from '../../common/ProcessingMsg/ProcessingMsg';
import { redirectToMyAccount } from '../../../../../../src/utils/utilities';
import {
  SignupChannel,
  SubscriptionType,
  getSubscriptionType,
  isSubscriptionOnHold,
  formatDate,
} from '../../../utils/subscriptions';
import { SUBSCRIPTION_STATES } from '../../../constants/subscription';
import RolloverPreference from '../rolloverPreference';
import useUpdateSubscriptionPreference from '../../../hooks/mutation/useUpdateSubscriptionPreference';
import { useIsRolloverAllowed } from '../../../hooks/useIsRolloverAllowed';

const ChangeSubscriptionTiering = ({ pageData, location }) => {
  const {
    chargifyPaymentProfile,
    paymentMethod,
    lmodSubscription,
    addressCountry,
    type,
    signupChannel,
  } = useContext(userContext);
  const currentPaymentInfo = chargifyPaymentProfile || paymentMethod;

  const [availablePlans, setAvailablePlans] = useState<Array<Product> | null>(null);
  const [countryCode, setCountryCode] = useState<string>('');
  const [selectedPlan, setSelectedPlan] = useState<Product>({} as Product);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [changeSubscriptionProcessing, setChangeSubscriptionProcessing] = useState<boolean>(false);
  const [changeSubscriptionSuccess, setChangeSubscriptionSuccess] = useState<boolean>(false);
  const [upgrading, setUpgrading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [diffRegionError, setDiffRegionError] = useState<boolean>(false);
  const [needNewToken, setNeedNewToken] = useState<boolean>(false);
  const [waitingTime, setWaitingTime] = useState<number>(WaitingTime.noWaiting);
  const [changeSub, setChangeSub] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const defaultInputValue = lmodSubscription?.default_to_monthly;
  const [inputValue, setInputValue] = useState(defaultInputValue);
  const [renderReminder, setRenderReminder] = useState(defaultInputValue);

  const newAccountPageEnabled = process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true';

  const availablePlansRef = useRef(null);
  const warningAlertRef = useRef(null);

  const urlParams = new URLSearchParams(location.search);
  const isFromApp = urlParams.get('from') === 'app';
  const preSelectedProduct = lmodSubscription?.product_handle;
  const disableChangeSubscriptionBtn =
    !selectedPlan?.id || preSelectedProduct === selectedPlan.product_handle;
  const subscriptionState = lmodSubscription?.state;

  const {
    prismicLesMillsPlusCommonLandingPage,
    prismicLesMillsPlusTieringLandingPage,
    prismicChangeSubscriptionPage,
    prismicSignupChannels,
  } = pageData;

  const signupChannelList = prismicSignupChannels?.data?.signup_channel;

  const subscriptionType = getSubscriptionType({
    subscription: lmodSubscription,
    type: type as SubscriptionType,
    userSignupChannel: signupChannel,
    signupChannelList: signupChannelList as unknown as SignupChannel[],
  });

  const isActive =
    subscriptionState === SUBSCRIPTION_STATES.ACTIVE ||
    subscriptionState === SUBSCRIPTION_STATES.TRIALING;

  const isOnHold = isSubscriptionOnHold(lmodSubscription?.state);

  const selectedAnnual = selectedPlan?.product_handle?.includes('annual');
  const showRolloverPreference =
    !['IAP', 'RESELLER'].includes(subscriptionType) && isActive && newAccountPageEnabled;

  const [showRollover, setShowRollover] = useState<boolean>(showRolloverPreference);

  const changeSubscriptionPagePrismicData = prismicChangeSubscriptionPage?.data;
  const planCardPrismicData = normalizeTieringPriceData(
    prismicLesMillsPlusTieringLandingPage,
    prismicLesMillsPlusCommonLandingPage
  );

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    title,
    warning_text,
    success_message_title,
    change_subscription_success_message_description,
    change_subscription_button_text,
    date_and_price,
    new_sub_starts,
    next_cycle,
    new_as_current,
    remain_balance,
    t_and_c_link,
    loading_text,
    loading_process_text,
    get_started_button_text,
    error_text_diff_region,
    change_subscription_subtitle,
    back_button_text,
    new_subscription,
    confirm,
    whats_next,
    cancel_anytime,
    monthly_text,
    annual_text,
    auto_renew_title,
    auto_renew_detail,
    renew_switch_info,
  } = changeSubscriptionPagePrismicData;

  const { currency, price } = selectedPlan;

  useEffect(() => {
    try {
      const getValidProductsQuery = async () => {
        const prices = await createPublicClient.query({
          query: getValidProducts,
          variables: {},
        });
        const normalizedPlans = normalizePlans(prices, 'tier');
        setCountryCode(prices.data.getHeaders.countryCode);
        setAvailablePlans(normalizedPlans);
      };
      getValidProductsQuery();
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  }, [preSelectedProduct]);

  useEffect(() => {
    if (preSelectedProduct && preSelectedProduct.includes('3monthly') && warningAlertRef?.current) {
      warningAlertRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    } else if (availablePlansRef?.current) {
      availablePlansRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }, [availablePlans, preSelectedProduct]);

  const currentTier = lmodSubscription?.product?.tier?.internal_tier_id?.toLowerCase();
  const showThreeMonthly = currentTier !== TIERS.BASE;
  const isTrial = lmodSubscription?.state?.toLowerCase() === 'trialing';

  const radioData = [
    { value: 'false', label: annual_text },
    { value: 'true', label: monthly_text },
  ];

  useEffect(() => {
    const targetTier = selectedPlan?.tier?.internal_tier_id.toLowerCase();

    // 1 Check if need refresh JWT token
    // for trial users, refresh token when there is tier change, base -> premium or premium -> base
    // for paid users, refresh token only when base -> premium
    if (isTrial) {
      setNeedNewToken(currentTier !== targetTier);
    } else {
      setNeedNewToken(currentTier === TIERS.BASE && targetTier === TIERS.PREMIUM);
    }

    if (currentTier === TIERS.BASE && targetTier === TIERS.PREMIUM) {
      setUpgrading(true);
    } else {
      setUpgrading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTier, selectedPlan, isTrial]);

  const isRolloverAllowed = useIsRolloverAllowed(selectedPlan);

  useEffect(() => {
    if (waitingTime === WaitingTime.noWaiting) return;
    if (waitingTime > WaitingTime.maxWaitingTime) navigate('/404');

    const refreshToken = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
          if (err) {
            addErrorInDatadogRum(err);
            navigate('/404');
          }
          const userInfoJwt = parseJwt(session?.idToken?.jwtToken);
          const tierIdInToken = userInfoJwt?.tierId;
          const tierIdInSelectedPlan = selectedPlan?.tier?.tier_id;
          if (tierIdInToken && tierIdInToken === tierIdInSelectedPlan) {
            localStorage.setItem('jwtToken', session?.idToken?.jwtToken);
            setChangeSubscriptionProcessing(false);
            setChangeSubscriptionSuccess(true);
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            setWaitingTime(waitingTime * 2);
          }
        });
      } catch (error) {
        addErrorInDatadogRum(error);
        navigate('/404');
      }
    };

    setTimeout(() => {
      refreshToken();
    }, waitingTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingTime]);

  const [handleUpdateSubscriptionPreference] = useUpdateSubscriptionPreference({
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
  });

  const handleChangeSubscription = async () => {
    try {
      setIsProcessing(true);
      handleUpdateSubscriptionPreference({
        variables: {
          rolloverOption: inputValue === 'true',
        },
      });

      const res = await createClient.mutate({
        mutation: changeSubscriptionChargifyJs,
        variables: {
          product_handle: selectedPlan?.id,
        },
      });
      if (res?.data?.changeSubscriptionChargifyJs) {
        setIsProcessing(false);
        // Only display loading page when needNewToken is true
        if (needNewToken) {
          setChangeSubscriptionProcessing(true);
          setWaitingTime(WaitingTime.minWaitingTime);
        } else {
          setChangeSubscriptionSuccess(true);
        }
      }
    } catch (error) {
      const isDiffRegion = addressCountry?.toLowerCase() !== countryCode;
      if (isDiffRegion) {
        setDiffRegionError(true);
      } else {
        setServerError(normalizeServerErrorMsg(error));
      }
      addErrorInDatadogRum(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBackClick = () => {
    if (changeSub && !confirmed) {
      setChangeSub(false);
      setShowRollover(true);
    }

    if (confirmed) {
      setConfirmed(false);
      setShowRollover(false);
    }
    if (!changeSub) {
      redirectToMyAccount();
    }
  };

  const handleRadioOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setRenderReminder(e.target.value === 'true');
  };

  const warningTexts = warning_text && warning_text.split('{planName}');

  const prismicPlaceHolders = {
    amount: '{amount}',
    date: '{date}',
  };

  const date = formatDate(lmodSubscription?.current_period_ends_at);
  const amount = priceFormat({ cents: price, currency });

  const dateAndPrice = date_and_price
    .replace(prismicPlaceHolders.amount, amount)
    .replace(prismicPlaceHolders.date, date);

  return (
    <SubscriptionManagementLayout
      theme="white"
      title={changeSubscriptionSuccess || changeSubscriptionProcessing ? '' : title}
      backLink={
        !isFromApp && !changeSubscriptionProcessing && !changeSubscriptionSuccess
          ? { handleClick: () => handleBackClick() }
          : null
      }
      subTitle={
        !newAccountPageEnabled
          ? changeSubscriptionSuccess || changeSubscriptionProcessing
            ? ''
            : change_subscription_subtitle
          : ''
      }
      size="medium"
      metaData={{
        title: 'Change Subscription',
      }}
    >
      {changeSubscriptionSuccess && (
        <SuccessMsg
          title={success_message_title}
          description={change_subscription_success_message_description}
          buttonText={get_started_button_text}
          onButtonClick={() => {
            window.location.href = '/';
          }}
        />
      )}
      {changeSubscriptionProcessing && needNewToken && (
        <ProcessingMsg loadingMsg={loading_text} loadingProcessMsg={loading_process_text} />
      )}
      {serverError && (
        <Alert content={serverError} style={{ width: '100%', marginBottom: '20px' }} />
      )}
      {diffRegionError && (
        <Alert style={{ width: '100%', marginBottom: '20px' }}>
          <RichText render={error_text_diff_region.richText} />
        </Alert>
      )}
      {preSelectedProduct && preSelectedProduct.includes('3monthly') && !showThreeMonthly && (
        <div ref={warningAlertRef}>
          <WarningAlert>
            <span>
              {warningTexts[0] || ''}
              <span className="warning-highlight">{lmodSubscription?.product?.name}</span>
              {warningTexts[1] || ''}
            </span>
          </WarningAlert>
        </div>
      )}
      {!changeSubscriptionSuccess && !changeSubscriptionProcessing && (
        <WrapperTiering ref={availablePlansRef}>
          {availablePlans && lmodSubscription?.product_handle && !confirmed && (
            <AvailableTieringSubscriptions
              displayOnlyCurrentPlan={!changeSub}
              changeSubscriptionPagePrismicData={changeSubscriptionPagePrismicData}
              planCardPrismicData={planCardPrismicData}
              countryCode={countryCode}
              availablePlans={availablePlans}
              currentProductHandle={lmodSubscription?.product_handle}
              getSelectedPlan={(plan) => {
                setSelectedPlan(plan);
              }}
              selectedPlan={selectedPlan}
              showThreeMonthly={showThreeMonthly}
            />
          )}

          {confirmed && (
            <>
              <NewSubWrapper>
                <AvailableTieringSubscriptions
                  displayOnlyCurrentPlan
                  changeSubscriptionPagePrismicData={changeSubscriptionPagePrismicData}
                  planCardPrismicData={planCardPrismicData}
                  countryCode={countryCode}
                  availablePlans={availablePlans}
                  currentProductHandle={lmodSubscription?.product_handle}
                  showThreeMonthly={showThreeMonthly}
                />
                <RightArrowIconWrapper>
                  <RightArrowIcon />
                </RightArrowIconWrapper>
                <AvailableTieringSubscriptions
                  displayOnlyCurrentPlan
                  changeSubscriptionPagePrismicData={changeSubscriptionPagePrismicData}
                  planCardPrismicData={planCardPrismicData}
                  countryCode={countryCode}
                  availablePlans={availablePlans}
                  currentProductHandle={selectedPlan.product_handle}
                  getSelectedPlan={(plan) => {
                    setSelectedPlan(plan);
                  }}
                  selectedPlan={selectedPlan}
                  showThreeMonthly={showThreeMonthly}
                  customBadgeText={new_subscription}
                />
              </NewSubWrapper>
              <SubTitle>{whats_next}</SubTitle>
              <TextWrapper>
                <div>
                  {`•  ${new_sub_starts} `}
                  <b>{upgrading || isTrial ? 'today.' : next_cycle}</b>
                </div>

                {(upgrading || isTrial) && <div>{`•  ${new_as_current}`}</div>}
                <div>{`•  ${dateAndPrice}`}</div>
                {upgrading && !isTrial && <div>{`•  ${remain_balance}`}</div>}
                <div>
                  {`•  ${cancel_anytime}`} <a href={t_and_c_link}>here.</a>
                </div>
              </TextWrapper>
            </>
          )}

          {!isOnHold && (
            <>
              <TermsCondition>
                {!changeSub && (
                  <LinkText
                    onClick={() => {
                      setChangeSub(true);
                      setShowRollover(false);
                    }}
                    className="edit-link"
                  >
                    {change_subscription_button_text}
                  </LinkText>
                )}
              </TermsCondition>
              {changeSub && !confirmed && (
                <PricingCardButtonContainer>
                  <Button
                    style={{
                      marginBottom: '0px',
                    }}
                    ctaButton
                    loading={isProcessing}
                    onClick={() => {
                      setConfirmed(true);
                      setShowRollover(selectedAnnual && isRolloverAllowed);
                    }}
                    disabled={disableChangeSubscriptionBtn}
                  >
                    <span>{confirm}</span>
                  </Button>
                </PricingCardButtonContainer>
              )}
            </>
          )}
          {!confirmed && <Divider />}

          <PaymentWrapper>
            {currentPaymentInfo && !confirmed && (
              <CurrentPaymentInfo
                prismicData={changeSubscriptionPagePrismicData}
                paymentInfo={currentPaymentInfo}
                selectedProductHandle={selectedPlan?.product_handle}
                urlParam={urlParams.toString()}
                isBorderTop
              />
            )}

            {showRollover && (
              <>
                <Divider />
                {confirmed ? (
                  <>
                    <Title>{auto_renew_title}</Title>
                    <Detail>{auto_renew_detail}</Detail>
                    <RadioGroup
                      id="subscription-rollover-preference"
                      ariaLabel="subscription rollover preference"
                      name="subscription-rollover-preference"
                      currentValue={inputValue}
                      handleOnChange={handleRadioOnChange}
                      radioData={radioData}
                    />
                    {renderReminder && <Reminder>{renew_switch_info}</Reminder>}
                  </>
                ) : (
                  <RolloverPreference prismicData={prismicChangeSubscriptionPage?.data} />
                )}
              </>
            )}
          </PaymentWrapper>
          {confirmed && (
            <PricingCardButtonContainer>
              <Button
                style={{
                  marginBottom: '0px',
                }}
                ctaButton
                loading={isProcessing}
                onClick={handleChangeSubscription}
                disabled={disableChangeSubscriptionBtn}
              >
                <span>{change_subscription_button_text}</span>
              </Button>
              <LinkText
                onClick={
                  !isFromApp && !changeSubscriptionProcessing && !changeSubscriptionSuccess
                    ? () => redirectToMyAccount()
                    : null
                }
              >
                {back_button_text}
              </LinkText>
            </PricingCardButtonContainer>
          )}
        </WrapperTiering>
      )}
    </SubscriptionManagementLayout>
  );
};

export default ChangeSubscriptionTiering;

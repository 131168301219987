/* eslint-disable no-case-declarations */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { debounce, kebabCase } from 'lodash';
import { Grid } from '@mui/material';
import { VideoCard, AlbumCard } from '@lesmills-international/components';
import { SlicePlaceholder } from '@components/atoms';
import { formatDuration } from '@services';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import GET_COLLECTION from '../../../engagementGql/collection';
import { createEngagementClient } from '../../../../../../src/services/client';
import {
  WorkoutCollectionItemProps,
  ExtrasCollectionItemProps,
  CollectionLinkItemProps,
  ItemType,
  WorkOutComponentProps,
} from '../workoutSpotlightRibbons';

import { PlaylistGridWrapper, Title } from './style';

interface Props {
  content: Queries.PrismicCollectionPageDataBodyPlaylistOrWorkoutGrid;
  planId?: string | null;
}

const GRID_BREAKPOINTS = [
  {
    width: 319,
    gridSize: 12,
    padding: '16px',
  },
  {
    width: 599,
    gridSize: 6,
    padding: '32px',
  },
  {
    width: 799,
    gridSize: 4,
    padding: '32px',
  },
  {
    width: 1023,
    gridSize: 3,
    padding: '80px',
  },
  {
    width: 1439,
    gridSize: 2.4,
    padding: '80px',
  },
  {
    width: 1799,
    gridSize: 2,
    padding: '80px',
  },
];

const PLAYLIST_GRID_BREAKPOINTS = [
  {
    width: 319,
    gridSize: 12,
    padding: '16px',
  },
  {
    width: 599,
    gridSize: 6,
    padding: '32px',
  },
  {
    width: 799,
    gridSize: 4,
    padding: '32px',
  },
  {
    width: 1439,
    gridSize: 3,
    padding: '80px',
  },
];

const getGridInfo = (width: number, contentType: string | null) => {
  let breakPointIndex = 0;
  const gridBreakpoints = contentType === 'workout' ? GRID_BREAKPOINTS : PLAYLIST_GRID_BREAKPOINTS;
  gridBreakpoints.forEach((breakPoint, index) => {
    if (breakPoint.width < width) breakPointIndex = index;
  });
  return gridBreakpoints[breakPointIndex];
};

const WorkoutItemComponent: FC<WorkOutComponentProps> = ({ data, planId }) => {
  const workoutItemData = useMemo(() => {
    switch (data?.__typename) {
      case ItemType.WORKOUT:
        const workoutData = data as WorkoutCollectionItemProps;
        if (!workoutData?.workout?.workoutName) {
          return null;
        }
        return {
          name: workoutData?.workout?.workoutName,
          backgroundImg: workoutData?.workout?.imageWide,
          equipmentIcons: workoutData?.workout?.equipment,
          duration: formatDuration(workoutData?.workout?.durationInSeconds || 0),
          link: `/workout/${encodeURIComponent(workoutData?.workout?.workoutName)}${
            planId ? `?planId=${planId}` : ''
          }`,
        };
      case ItemType.EXTRA:
        const extraData = data as ExtrasCollectionItemProps;
        return {
          name: extraData?.name,
          duration: '',
          equipmentIcons: [],
          backgroundImg: extraData?.vimeoThumbnail,
          link: extraData?.downloadUrl,
        };
      default:
        return null;
    }
  }, [data, planId]);

  return workoutItemData ? <VideoCard {...workoutItemData} /> : null;
};

const PlaylistGrid: FC<Props> = ({ content, planId }) => {
  const contentType = content?.primary?.content_type;
  const collectionId = content?.primary?.collection_id;
  const [playlistItemsData, setPlaylistItemsData] = useState<
    (WorkoutCollectionItemProps | ExtrasCollectionItemProps | CollectionLinkItemProps)[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeInterval = 100;
    const handleResize = debounce(() => setCurrentWidth(window.innerWidth), resizeInterval);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleGetPlaylistData = async () => {
      try {
        setLoading(true);
        const data = await createEngagementClient.query({
          query: GET_COLLECTION,
          variables: {
            id: Number(collectionId),
            page: 1,
            perPage: 100,
          },
        });
        if (data?.data?.collection?.items) {
          setLoading(false);
          setPlaylistItemsData(data?.data?.collection?.items);
        }
      } catch (error) {
        setLoading(false);
        addErrorInDatadogRum(error);
      }
    };
    handleGetPlaylistData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { gridSize } = getGridInfo(currentWidth, contentType);

  return (
    <PlaylistGridWrapper>
      <Title>{content?.primary?.title}</Title>
      {loading ? (
        <SlicePlaceholder height="284px" />
      ) : (
        <Grid container spacing={2} role="list">
          {playlistItemsData?.map((item, index) => {
            if (contentType === 'workout' && !item?.workout?.workoutName) {
              return null;
            }
            return (
              <Grid item xs={gridSize} key={index} role="listitem">
                {contentType === 'workout' ? (
                  <WorkoutItemComponent data={item} planId={planId} />
                ) : (
                  <AlbumCard
                    title={item?.name}
                    backgroundImg={item?.vimeoThumbnail}
                    description={item?.description}
                    link={`/collection/${item?.slug || kebabCase(item?.name)}`}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </PlaylistGridWrapper>
  );
};

export default PlaylistGrid;
